import { Component, Input } from '@angular/core'
import { Header } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  language: Header = this.languageService.header

  @Input() phone: string = ''
  @Input() centerName: string = ''
  @Input() siteUrl: string = ''


  constructor(
    private languageService: LanguageService
  ) { }

  pathname: string = window.location.pathname.replace('/concluido', '')

  conhecaUnidade = `${this.pathname}/#${this.language.linkMeetCenter}`
  metodo = `${this.pathname}/#${this.language.linkMethod}`
  depoimentos = `${this.pathname}/#${this.language.linkDepositions}`

}
