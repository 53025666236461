export const environment = {
  production: false,
  API: 'https://agendamentoapitst.kumon.com.br',
  centerMailBR: 'BKTST@unidadekumon.com.br',
  centerMailLA: 'BKTST@unidadkumon.com',

  usernameAppointment: "Appointment",
  passwordAppointment: "!$%appt#4h6KfY/mkv)[D%GtNSu(sR*I{",

  usernameAgendamentoOnline: "AgendamentoOnline",
  passwordAgendamentoOnline: "z;PZ>#54jF6_vWm2DxB@eH9Q<XNTwu[UdVn%aER3$S}yY8fpG7",

  appSecretKey: "J[cL$nvFuE%N9HMpZDQ]RBhm>q;_fTtP@r6j3A8Ua<edk4zG@x",

  APIenrollment: 'https://eenrollmentapitst.kumon.com.br',
  usernameEnrollment: 'AppMOL',
  passwordEnrollment: "QeV+#4h6KfU/mkv)[D%GtNSu(sR*H{",
  keyEnrollment: 'N:-p9B?u*2V=h}Fm<c8a/AfwCqdr!jgRWkxMJ@;ZHPT6Q'
}
