
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2'
import { Subscription, concatMap, iif, map, of, switchMap, take, tap } from 'rxjs'
import { ApiService } from 'src/app/services/api.service'
import { ParamsService } from 'src/app/services/params.service'
import { environment } from 'src/environments/environment'
import { DadosService } from './../../services/dados.service'

import { Availability } from 'src/app/models/availability.model'
import { Center } from 'src/app/models/center.model'
import { Disciplinas } from 'src/app/models/disciplinas.model'
import { SelectedDate } from 'src/app/models/selectedDate.model'
import { Service } from 'src/app/models/service.model'

import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as _ from 'lodash'
import { ErrorMessage, Homepage, RequestService } from 'src/app/models/language.model'
import { AuthService } from 'src/app/services/auth.service'
import { LanguageService } from 'src/app/services/language.service'

dayjs.extend(customParseFormat)

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  getData$!: Subscription
  getSelectedService$!: Subscription
  schedule$!: Subscription
  reschedule$!: Subscription

  language: Homepage = this.languageService.homePage
  languageResquestService: RequestService = this.languageService.requestService
  languageErrorMessage: ErrorMessage = this.languageService.errorMessage

  // Informações da unidade
  countryId: string = this.languageService.country
  urlParams = {
    state: this.route.snapshot.params['state'],
    local: this.route.snapshot.params['local'],
    hash: this.route.snapshot.params['hash']
  }
  // pathname: string = window.location.pathname.replace('/concluido', '') /
  pathname: string = `${this.urlParams.state}/${this.urlParams.local}` 

  bookingId: string = ''
  pathSearchCenter: string = this.languageResquestService.pathSearchCenter
  private centerUrl: string = `${this.pathname}?country=${this.countryId}` 

  siteUrl: string = `https://www.kumon${this.countryId === 'CL' ? '' : '.com'}.${this.countryId.toLowerCase()}`

  private centerMail: string = this.countryId === 'BR'
    ? environment.centerMailBR
    : environment.centerMailLA

  // Site Center
  center: Center = {
    id: '',
    country: '',
    centerName: '',
    phone: '',
    courses: '',
    isVirtual: null,
    timeZone: '',
  }
  disciplinas: Disciplinas[] = []

  // Booking Services List 
  servicesList: Service[] = []
  selectedService!: Service
  defaultDuration: string = ''

  // Staff Avaiability
  staffAvaiability: any[] = []

  // Calendar
  slotInterval: any = []
  selectedDate: string = ''
  calendarRange = {
    start: 1,
    end: 2
  }

  // Autenticação
  isAuthenticated: boolean = true

  // Tela de loading
  isLoading = false
  textLoading: string = this.language.loadingCenterInfo

  //Tela de Erro
  textMessage: string = this.languageErrorMessage.centerInfoNotLoaded
  labeMessageButton: string = this.language.buttonTryAgain
  iconMessage: string = 'error'
  isStaffAvailable: boolean = true

  // Botão
  buttonAgendar: string = this.language.buttonSchedule
  buttonReagendar: string = this.language.buttonReschedule
  isButtonLoading: boolean = false
  checkPrivacidade: any
  isButtonConfirm!: boolean

  // Timezone
  hasTimezone = true
  timezoneText = ''

  // Validação formulário
  isFormValid = {
    modalidade: false,
    calendario: false,
    duracao: false,
    dadosResponsavel: false,
    privacidade: false
  }

  // Modal contato
  modalContato: boolean = false

  // Carregar informações do Responsável (Hash)
  guardianName = ''

  classSubjects: any[] = []
  centerId: any
  stateId: any

  // Séires Escolares
  schoolGrade = []

  // Appointment
  appointment = {
    onlineMeetingUrl: "", //vazio
    joinWebUrl: "", //vazio
    serviceId: "",
    optOutOfCustomerEmail: false,
    leadSourceID: this.paramsService.getParams().tema ? 4 : 0,
    sourceDetail: this.paramsService.getParams().tema || '',
    staffMemberIds: [
      ""
    ],
    start: {
      dateTime: "", //2023-03-17T14:00
      timeZone: ""
    },
    end: {
      dateTime: "", //2023-03-17T14:00
      timeZone: ""
    },
    serviceNotes: "",
    serviceLocation: {
      displayName: "", //vazio
      locationEmailAddress: "", //vazio
      locationUri: `${this.urlParams.state}/${this.urlParams.local}`,
      locationType: "default",
      address: {
        type: "unknown",
        postOfficeBox: "", //vazio
        street: "", //vazio
        city: "", //vazio
        state: "", //vazio
        countryOrRegion: this.countryId,
        postalCode: "" //vazio
      },
      coordinates: {
        altitude: "NaN",
        latitude: "NaN",
        longitude: "NaN",
        accuracy: "NaN",
        altitudeAccuracy: "NaN"
      }
    },
    reminders: [
      {
        "@odata.type": "#microsoft.graph.bookingReminder",
        message: "",
        offset: "P1D",
        "recipients@odata.type": "#microsoft.graph.bookingReminderRecipients",
        recipients: "allAttendees"
      }
    ],
    customers: [
      {
        "@odata.type": "#microsoft.graph.bookingCustomerInformation",
        customerId: "", //vazio
        name: "",
        emailAddress: "",
        phone: ""
      }
    ],
  }

  @ViewChild('sendEmail') private readonly sendEmail!: SwalComponent
  @ViewChild('sendEmailErro') private readonly sendEmailErro!: SwalComponent
  @ViewChild('responsavel') responsavel!: ElementRef

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dados: DadosService,
    public paramsService: ParamsService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {

    this.getData()
    
    this.dados.setDados('appointment', this.appointment)
    
     if(this.hasAppointmentParams() || !!this.urlParams.hash) { 
      
      this.dados.setDados('appointment', {hash: this.urlParams.hash})

      let params = this.paramsService.getParams()

      this.dados.setDados('params', {
        appointmentId: params.appointmentId,
        bookingId: params.bookingId
      })

      this.isFormValid = {
        modalidade: true,
        calendario: false,
        duracao: false,
        dadosResponsavel: true,
        privacidade: false
      }
    
    } else {
      this.irParaAgendamento()
    }

  }

  getData() {
    this.isLoading = true

    this.getData$ = this.auth.authAppointment()
      .pipe(

        concatMap(() => this.auth.authAgendamentoOnline()),

        concatMap(() => this.auth.authEnrollment()),

        concatMap(() => {
          return iif( ()=> !!this.urlParams.hash,
            this.apiService.getLeadDetails(this.urlParams.hash),
            of(null)
          ) 
        }),

        tap( (res:any) => {
          
          if (res) {
            this.guardianName = res.nome
          }
          
        }),

        concatMap(() => this.apiService.getCenter(this.centerUrl)),

        tap((res: Center) => {
          this.center = res
          this.bookingId = res.id + this.centerMail
          this.displayTimezone(res.timeZone)

          let courses = this.countryId !== 'BR'
            ? this.translateCourses(res.courses)
            : res.courses

          this.centerId = res.country;
          this.stateId = res.id ? res.id.slice(2) : '';
        }),

        
        concatMap(() => {
          return this.apiService.getClasssubjects(this.centerId, this.stateId)
        }),

        
        tap((res: any) => {
          console.log('➡️  res: ', res)
          this.classSubjects = res; 

          this.disciplinas = res.map(( course: any) => {
            return {
              id: course.classSubjectID,
              value:course.classSubject
            }
          })
        }),

        concatMap(() => {
          return this.apiService.bookingServiceList(this.bookingId)
        }),

        tap(res => {
          this.servicesList = _.orderBy(res.value, ['displayName'], ['desc'])
          this.textLoading = this.language.loadingUpdateCalendar
        }),

        concatMap(() => {
          return this.apiService.selectedService$
        }),

        tap((res: Service) => {
          this.selectedService = res
          this.defaultDuration = res.defaultDuration
          this.dados.setDados('appointment', { serviceId: res.id, staffMemberIds: res.staffMemberIds })
        }),

        concatMap(res => {

          let { start, end } = this.calendarRange

          let availability: Availability = {
            staffIds: res.staffMemberIds,
            startDateTime: {
              dateTime: dayjs().add(start, 'day').format(),
              timeZone: this.center.timeZone
            },
            endDateTime: {
              dateTime: dayjs().add(end, 'month').hour(23).minute(59).format(),
              timeZone: this.center.timeZone
            }
          }

          return this.apiService.getStaffAvaiability(availability, this.bookingId)

        }),

        map((res: any) => {

          this.staffAvaiability = _.flatMap(res.value, (item) => {
            return _.filter(item.availabilityItems, { status: 'available' })
          })

          if (this.staffAvaiability.length === 0) {
            this.isAuthenticated = false
            this.iconMessage = 'info'
            this.isStaffAvailable = false
            this.textMessage = this.languageErrorMessage.centerUnavailable
          }

        }),

        concatMap(()=>this.apiService.getStudentGrade(this.countryId)),

        tap( res => this.schoolGrade = res),

        take(1)
      )
      .subscribe({
        next: (res) => {
          window.scroll(0, 0)
          this.isLoading = false
          this.onSelectService()
        },
        error: (err) => {
          this.isAuthenticated = false
          this.isLoading = false
        }
      })
  }

  hasAppointmentParams() {
    return this.paramsService.getBookingStatus()
  }

  onSelectService() {
    this.getSelectedService$ = this.apiService.selectedService$.subscribe(
      res => {
        this.selectedService = res
        this.dados.setDados('appointment', { serviceId: res.id, staffMemberIds: res.staffMemberIds })
      }
    )
  }

  onSelectDate(e: SelectedDate) {
    this.selectedDate = e.date
    this.slotInterval = e.slotInterval
  }

  onSelectTime(initialTime: string) {

    let timeDuration = dayjs.duration(this.defaultDuration).asMinutes()
    let endTime = dayjs(initialTime, 'HH:mm').add(timeDuration, 'minutes').format('HH:mm')

    let start = {
      dateTime: dayjs(this.selectedDate + initialTime).format('YYYY-MM-DDTHH:mm'),
      timeZone: this.center.timeZone
    }

    let end = {
      dateTime: dayjs(this.selectedDate + endTime).format('YYYY-MM-DDTHH:mm'),
      timeZone: this.center.timeZone
    }

    this.dados.setDados('appointment', { start, end })

    this.dados.setDados('reschedule', {
      startDateTime: start,
      endDateTime: end
    })

    if (!this.hasAppointmentParams() && !this.urlParams.hash) {
      this.responsavel.nativeElement.scrollIntoView({
        behaviour: 'smooth'
      })
    }

  }

  translateCourses(courses: string) {

    let translatedCourses

    const translate = {
      "Matemática": "Matemáticas",
      "Inglês": "Inglés"
    }

    translatedCourses = courses.replace(/Inglês|Matemática/gi, matched => {
      return translate[matched as keyof typeof translate]
    })

    switch (this.countryId) {
      case 'AR':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Lengua Materna')
        break

      case 'CL':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Lenguaje')
        break

      case 'CO':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Español')
        break

      case 'BO':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Lenguaje')
        break

      case 'PE':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Lenguaje')
        break

      case 'UY':
        translatedCourses = translatedCourses?.replace('Espanhol', 'Lengua')
        break
    }

    return translatedCourses

  }

  agendar() {

    if (!this.isButtonConfirm) return

    this.textLoading = this.language.loadingFinishAppointment

    this.isLoading = true

    let appointment = this.dados.getDados('appointment')

    this.schedule$ = this.apiService.newAppointment(
      appointment,
      this.bookingId,
      this.selectedService.isLocationOnline)
      .subscribe({
        next: (res: any) => {

          if (this.paramsService.getParams().tema) {
            let w = (window as any)

            w.dataLayer = w.dataLayer || []
            w.dataLayer.push({
              'event': 'cadastro_agendamento'
            })
          }

          this.router.navigate(
            [`concluido/${this.urlParams.state}/${this.urlParams.local}`],
            {
              queryParams: {
                bookingId: this.bookingId,
                appointmentId: res.id
              }
            }
          )

          this.isLoading = false
        },
        error: err => {
          this.isAuthenticated = false
          this.textMessage = this.languageErrorMessage.createBooking
          this.isLoading = false
        }
      })
  }

  reagendar() {

    if (!this.isButtonConfirm) return

    this.textLoading = this.language.loadingUpdateAppointment

    this.isLoading = true

    let reschedule = this.dados.getDados('reschedule')

    this.reschedule$ = this.apiService.updateAppointment(
      this.dados.getDados('params').bookingId,
      this.dados.getDados('params').appointmentId,
      reschedule
    ).subscribe({
      next: (res: any) => {

        this.router.navigate([`concluido/${window.location.pathname}`], { 
          queryParams: {
            bookingId: this.dados.getDados('params').bookingId,
            appointmentId: res.id
          },
          replaceUrl: true
        })

        this.isLoading = false
      },
      error: err => {
        this.isAuthenticated = false
        this.isLoading = false
      }
    })
  }

  goToPrivacy() {
    let privacy

    switch (this.countryId) {

      case 'CL':
        privacy = `https://www.kumon.cl/${this.language.privacyURL}`
        break

      default:
        privacy = `https://www.kumon.com.${this.countryId}/${this.language.privacyURL}`
        break
    }

    return privacy
  }

  onCheckPrivacy(e: any) {
    let statusCheck = e.target as HTMLInputElement

    this.isFormValid = {
      ...this.isFormValid,
      privacidade: statusCheck.checked
    }

    this.enableButton()
  }

  getFormStatus(status: boolean, component: string) {

    this.isFormValid = {
      ...this.isFormValid,
      [component]: status
    }

    this.enableButton()

  }

  enableButton() {
    let allFormValid = Object.values(this.isFormValid).every(Boolean)

    this.isButtonConfirm = allFormValid
  }

  tryAgain() {
    window.location.reload()
  }

  irParaAgendamento() {
    return this.router.navigate([`${this.pathname}`], { queryParams: null, replaceUrl: true })
  }

  showModalContato(e: Event) {
    e.preventDefault()
    this.modalContato = true
  }

  closeModal(event: any) {

    if (event) {
      this.modalContato = false
      this.sendEmail.fire()
    } else {
      this.modalContato = false
      this.sendEmailErro.fire()
    }

  }

  displayTimezone(value: string) {

    const timezones = [
      {
        name: 'Brasília (UTC-03:00)',
        value: 'E. South America Standard Time'
      },
      {
        name: 'Araguaína (UTC-03:00)',
        value: 'Tocantins Standard Time'
      },
      {
        name: 'Fortaleza (UTC-03:00) ',
        value: 'SA Eastern Standard Time'
      },
      {
        name: 'Salvador (UTC-03:00)',
        value: 'Bahia Standard Time'
      },
      {
        name: 'Cuiabá (UTC-04:00)',
        value: 'Central Brazilian Standard Time'
      },
      {
        name: 'Manaus (UTC-04:00)',
        value: 'SA Western Standard Time'
      },
      {
        name: 'Rio Branco (UTC-05:00)',
        value: 'SA Pacific Standard Time'
      }
    ]

    try {

      if (this.countryId === 'BR') {

        const timezone = timezones.find(item => item.value === value)
        this.timezoneText = timezone!.name

      } else {
        this.hasTimezone = false
      }

    } catch {
      this.timezoneText = value
    }

  }

  ngOnDestroy(): void {
    this.getData$.unsubscribe()
    // this.getSelectedService$.unsubscribe()
  }

}
