<form [formGroup]="form">
  <div class="container-form">
    <div class="nome">
      <span class="p-float-label">
        <input type="text" pInputText class="p-inputtext-sm" formControlName="name" maxlength="50" (keypress)="onInputName($event)" />
        <label>{{language.labelFullName}}</label>
      </span>

      <div
        *ngIf="
              form.get('name')?.invalid &&
              (form.get('name')?.dirty ||
                form.get('name')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('name')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('name')?.errors?.['pattern']"
          [textError]="languageError.fullName"></app-input-error>
      </div>
    </div>

    <div class="email">
      <span class="p-float-label">
        <input type="email" pInputText class="p-inputtext-sm" formControlName="emailAddress" />
        <label>{{language.labelEmail}}</label>
      </span>

      <div
        *ngIf="
              form.get('emailAddress')?.invalid &&
              (form.get('emailAddress')?.dirty ||
                form.get('emailAddress')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['email']"
          [textError]="languageError.invalidEmail"></app-input-error>
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['pattern']"
          textError="E-mail inválido."></app-input-error>
      </div>
    </div>

    <div class="celular">
      <span class="p-float-label">
        <input
          type="text"
          pInputText
          [placeholder]="placeholder"
          [mask]="format"
          inputmode="numeric"
          formControlName="phone"
          (keypress)="onInputPhone($event)"
          class="p-inputtext-sm" />
        <label>{{language.labelCellphone}}</label>
      </span>

      <div
        *ngIf="
              form.get('phone')?.invalid &&
              (form.get('phone')?.dirty ||
                form.get('phone')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('phone')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('phone')?.errors?.['minlength']"
          [textError]="languageError.invalidPhone"></app-input-error>
      </div>
    </div>
  </div>
</form>