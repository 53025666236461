import { Injectable, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(
    private route: ActivatedRoute,
  ) {
    this.handleQueryParams()
  }

  private hasBookingParams!: boolean
  private queryParams: any

  private handleQueryParams() {

    this.route.queryParams.subscribe((res: any) => {
      this.queryParams = res
      this.hasBookingParams = !!res.bookingId && !!res.appointmentId
    })
  }

  getBookingStatus(): boolean {
    return this.hasBookingParams
  }

  getParams() {
    return this.queryParams
  }

}
