import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Directive, DoCheck, OnChanges, OnDestroy, OnInit } from "@angular/core"

let nextId = 1

// Spy on any element to which it is applied.
// Usage: <div appSpy>...</div>
@Directive({ selector: '[appSpy]' })
export class SpyDirective implements OnInit, OnDestroy, OnChanges, DoCheck, AfterViewInit, AfterViewChecked, AfterContentInit, AfterContentChecked {
    private id = nextId++;

    constructor() { }

    ngOnChanges() {
        console.log(`Spy #${this.id} OnChanges`)
    }

    ngOnInit() {
        console.log(`Spy #${this.id} onInit`)
    }

    ngDoCheck() {
        console.log(`Spy #${this.id} DoCheck`)
    }

    ngAfterContentInit() {
        console.log(`Spy #${this.id} AfterContentInit`)
    }

    ngAfterContentChecked() {
        console.log(`Spy #${this.id} AfterContentChecked`)
    }

    ngAfterViewInit() {
        console.log(`Spy #${this.id} AfterViewInit`)
    }

    ngAfterViewChecked() {
        console.log(`Spy #${this.id} AfterViewChecked`)
    }

    ngOnDestroy() {
        console.log(`Spy #${this.id} onDestroy`)
    }

}