import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private appSecretKey: string = environment.appSecretKey
  private api = `${environment.API}/api/Authentication`

  private keyEnrollment = environment.keyEnrollment
  private apiEnrollment = `${environment.APIenrollment}/api/Authentication`

  private userAppointment = {
    id: 0,
    username: environment.usernameAppointment,
    password: environment.passwordAppointment,
    role: "string"
  }

  private userAgendamentoOnline = {
    id: 0,
    username: environment.usernameAgendamentoOnline,
    password: environment.passwordAgendamentoOnline,
    role: "string"
  }

  private userEnrollment = {
    id: 0,
    username: environment.usernameEnrollment,
    password: environment.passwordEnrollment,
    role: "string"
  }

  params = new HttpParams().append('appSecretKey', this.appSecretKey)

  constructor(
    private http: HttpClient
  ) { }

  authAppointment() {

    return (
      this.http.post<string>(`${this.api}/login`, this.userAppointment, { params: this.params })
        .pipe(
          map((data: any) => {
            sessionStorage.setItem('token-appointment', data.token)
            return data.token
          })
        ))
  }

  authAgendamentoOnline() {

    return (
      this.http.post<string>(`${this.api}/login`, this.userAgendamentoOnline, { params: this.params })
        .pipe(
          map((data: any) => {
            sessionStorage.setItem('token-AgendamentoOnline', data.token)
            return data.token
          })
        ))
  }

  authEnrollment() {
    return this.http.post<string>(`${this.apiEnrollment}/login`, this.userEnrollment, {
      params: new HttpParams().append('appSecretKey', this.keyEnrollment)
    })
    .pipe(
      map( (data: any) => {
        sessionStorage.setItem('token-Enrollment', data.token)
        // console.log(data.token);
        
        return data.token
      })
    )
  }

  whoAmI() {
    return this.http.get<string>(`${this.api}/whoami`)
  }
}
