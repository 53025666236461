import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InputError, RequestService } from 'src/app/models/language.model'
import { ApiService } from 'src/app/services/api.service'
import { AppService } from 'src/app/services/app.service'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-contato-email',
  templateUrl: './contato-email.component.html',
  styleUrls: ['./contato-email.component.scss']
})
export class ContatoEmailComponent implements OnInit {

  language: RequestService = this.languageService.requestService
  languageError: InputError = this.languageService.inputError
  country: string = this.language.country

  @Input() bookingId = ''

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  // Mensagem
  maxlength = 200
  message = ''
  currentCharacter: any = 0
  btnLabel = this.language.buttonSend
  btnIcon = 'fa-arrow-right'
  btnDisabled = false

  // Telefone
  format = ''
  placeholder = ''

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž']+\s+[a-zA-ZÀ-ž']+/)]),
    emailAddress: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\.\-\+_]*@[a-zA-Z0-9]{1,}[a-zA-Z0-9\.\-_]*\.[a-z]{2,}$/)]),
    phone: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
  })

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private languageService: LanguageService
  ) { }


  ngOnInit(): void {
    this.changeFormByCountry()
  }


  getMessageValue() {
    this.currentCharacter = this.form.value.message?.split('').length
  }


  sendEmail() {

    this.btnLabel = this.language.buttonSending
    this.btnIcon = ''
    this.btnDisabled = true

    let data = {
      ...this.form.value,
      bookingId: this.bookingId
    }

    console.log(data)

    this.apiService.sendEmailContato(data).subscribe({
      next: res => {

        console.log('form res', res)
        this.closeModal.emit(res)

        this.form.reset()
        this.btnLabel = this.language.buttonSend
        this.btnIcon = 'fa-arrow-right'
        this.btnDisabled = false
      },
      error: err => {
        this.closeModal.emit(false)
        this.form.reset()
        console.log(err)

        this.btnLabel = this.language.buttonSend
        this.btnIcon = 'fa-arrow-right'
        this.btnDisabled = false
      }
    })
  }

  changeFormByCountry() {
    switch (this.country) {

      case 'BR':
        this.format = '(00) 00000-0000'
        this.placeholder = '(00) 00000-0000'
        this.form.get('phone')?.setValidators([Validators.required, Validators.minLength(11)])
        this.form.get('phone')?.updateValueAndValidity()
        break

      default:
        this.format = '99999999999'
        this.form.get('phone')?.setValidators([Validators.required, Validators.minLength(5)])
        this.form.get('phone')?.updateValueAndValidity()
    }
  }

  onInputName(e: any) {
    return this.appService.alphaOnly(e)
  }

  onInputPhone(e: any) {
    return this.appService.numbersOnly(e)
  }
}
