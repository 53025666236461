<form [formGroup]="form" (ngSubmit)="sendEmail()">
  <div class="container-form">
    <div class="nome">
      <span class="p-float-label">
        <input type="text" pInputText class="p-inputtext-sm" formControlName="name" (keypress)="onInputName($event)" />
        <label>{{ language.labelFullName }}</label>
      </span>

      <div
        *ngIf="
              form.get('name')?.invalid &&
              (form.get('name')?.dirty ||
                form.get('name')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('name')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('name')?.errors?.['pattern']"
          [textError]="languageError.fullName"></app-input-error>
      </div>
    </div>

    <div class="email">
      <span class="p-float-label">
        <input type="email" pInputText class="p-inputtext-sm" formControlName="emailAddress" />
        <label>{{ language.labelEmail }}</label>
      </span>
      <div
        *ngIf="
              form.get('emailAddress')?.invalid &&
              (form.get('emailAddress')?.dirty ||
                form.get('emailAddress')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['email']"
          [textError]="languageError.invalidEmail"></app-input-error>
        <app-input-error
          *ngIf="form.get('emailAddress')?.errors?.['pattern']"
          textError="E-mail inválido."></app-input-error>
      </div>
    </div>

    <div class="celular">
      <span class="p-float-label">
        <input
          type="text"
          pInputText
          [placeholder]="placeholder"
          [mask]="format"
          inputmode="numeric"
          formControlName="phone"
          (keypress)="onInputPhone($event)"
          class="p-inputtext-sm" />
        <label>{{ language.labelCellphone }}</label>
      </span>
      <div
        *ngIf="
              form.get('phone')?.invalid &&
              (form.get('phone')?.dirty ||
                form.get('phone')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('phone')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
        <app-input-error
          *ngIf="form.get('phone')?.errors?.['minlength']"
          [textError]="languageError.invalidPhone"></app-input-error>
      </div>
    </div>

    <div class="mensagem">
      <span class="p-float-label">
        <textarea
          pInputTextarea
          [maxlength]="maxlength"
          formControlName="message"
          (keyup)="getMessageValue()"
          rows="3"></textarea>
        <label>{{ language.labelMessage }}</label>
      </span>
      <div class="maxlength">
        <span>{{ currentCharacter }} / {{ maxlength }}</span>
      </div>
      <div
        *ngIf="
              form.get('message')?.invalid &&
              (form.get('message')?.dirty ||
                form.get('message')?.touched)
            ">
        <app-input-error
          *ngIf="form.get('message')?.errors?.['required']"
          [textError]="languageError.required"></app-input-error>
      </div>
    </div>
  </div>

  <p class="text-orbigatorio">{{ language.requiredFields }}</p>

  <div class="modal-btn">
    <app-button
      type="submit"
      [label]="btnLabel"
      [rightIcon]="btnIcon"
      [disabled]="!form.valid || btnDisabled"></app-button>
  </div>
</form>