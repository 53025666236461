<!-- **** Loading **** -->
<app-loading *ngIf="isLoading" [textLoading]="textLoading"></app-loading>

<app-header [centerName]="center.centerName" [phone]="center.phone" [siteUrl]="siteUrl"></app-header>


<div class="container-xl" [ngSwitch]="bookingStatus">
  <div class="wrapper">
    <div class="tela-final">
      <!-- TELA CONCLUÍDO -->
      <ng-container *ngSwitchDefault>
        <div class="titulo concluido">
          <img src="../../../assets/images/icon-complete.svg" alt="" />
          <h1>{{ language.textSuccess }}</h1>
        </div>

        <div class="conteudo conteudo_concluido">
          <div class="mensagem">
            <h2>{{language.textTitle}}</h2>

            <p>
              {{ language.textEmail }}
              <strong>{{ email }}</strong>.
              {{ language.textEmailNotFound }}
            </p>

            <p *ngIf="!isOnline">
              {{ language.textAdvanceNotice }}
            </p>

            <p>
              {{ language.textAboutKumon }}
            </p>

            <p class="email">{{ language.textThankYou }}</p>

            <app-button
              (click)="irParaPaginaUnidade()"
              [label]="language.buttonBackToCenterPage"></app-button>
          </div>

          <div class="resumo">
            <h2>
              <span *ngIf="!isOnline; else unidadeVirtual">
                {{ language.textInfoOffline }}
              </span>
              <ng-template #unidadeVirtual>
                {{ language.textInfoOnline }}
              </ng-template>
              <b>{{ unidade }}</b>
            </h2>
            <div class="item">
              <i class="fas fa-calendar-alt"></i>
              <span>{{ date }}</span>
            </div>

            <div class="item">
              <i class="fas fa-clock"></i>
              <span>{{ time }}</span>
            </div>

            <div class="item">
              <i class="fas fa-map-marker-alt"></i>
              <!-- <span>R. Dr. Tomás Carvalhal, 686 Paraíso - SP</span> -->

              <span *ngIf="!isOnline; else atendimentoVirtual">{{ location }}</span>
              <ng-template #atendimentoVirtual>
                <span>{{language.textVirtualService}}</span>
              </ng-template>
            </div>

            <div class="item">
              <i class="fas fa-phone"></i>
              <span>{{ phone }}</span>
            </div>

            <div class="item" *ngIf="isOnline">
              <i class="fas fa-link"></i>
              <a [href]="url">{{language.textAccessLink}}</a>
            </div>

            <div class="acoes">
              <p>
                <a href="" [swal]="reagendar" (click)="$event.preventDefault()"> {{ language.buttonReschedule }}</a>
              </p>
              <p>
                <a href="" [swal]="cancelar" (click)="$event.preventDefault()">{{language.buttonCancel}}</a>
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- TELA DE CANCELAMENTO -->
      <ng-container *ngSwitchCase="'cancelado'">
        <div class="titulo cancelado">
          <img src="../../../assets/images/icon-canceled.svg" alt="" />
          <h1>{{language.textCancel}}</h1>
        </div>

        <div class="conteudo conteudo_cancelado">
          <div class="mensagem">
            <h2>
              {{language.textWeAreSorry}}
            </h2>

            <p>
              {{language.textFindNewAppointment}}
            </p>

            <app-button [label]="language.buttonNewAppointment" (click)="irParaAgendamento()"></app-button>
          </div>
        </div>
      </ng-container>

      <!-- MENSAGEM DE ERRO -->
      <ng-container *ngSwitchCase="'erro'">
        <app-message icon="error">
          <p>
            {{ textMessage }}
          </p>
          <app-button [label]="labeMessageButton" (click)="reloadPage()"></app-button>
        </app-message>
      </ng-container>
    </div>
  </div>
</div>

<!-- **** Alerta **** -->
<swal
  #reagendar
  [title]="language.alertRescheduleTitle"
  [icon]="AlertIcon"
  (confirm)="confirmaReagendamento()"
  [showCancelButton]="true"
  [cancelButtonText]="AlerCancelButtonText"
  [confirmButtonText]="confirmRescheduleButtonText"
  [focusCancel]="true">
</swal>

<swal
  #cancelar
  [title]="language.alertCancelTitle"
  [icon]="AlertIcon"
  (confirm)="cancelAppointment()"
  [showCancelButton]="true"
  [cancelButtonText]="AlerCancelButtonText"
  [confirmButtonText]="confirmCancelButtonText"
  [focusCancel]="true">
</swal>

<app-version></app-version>