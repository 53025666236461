import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms'
import { Component, Input, OnInit } from '@angular/core'
import { DadosService } from 'src/app/services/dados.service'

import * as _ from 'lodash'
import { Disciplinas } from 'src/app/models/disciplinas.model'
import { LanguageService } from 'src/app/services/language.service'
import { Child } from 'src/app/models/language.model'

@Component({
  selector: 'app-dados-aluno',
  templateUrl: './dados-aluno.component.html',
  styleUrls: ['./dados-aluno.component.scss']
})
export class DadosAlunoComponent implements OnInit {

  countryId = this.languageService.country
  language: Child = this.languageService.child
  
  serviceNotes = ''
  form!: FormGroup
  
  @Input() schoolGrade: any
  @Input() disciplinas: Disciplinas[] = []

  constructor(
    private fb: FormBuilder,
    private dados: DadosService,
    private languageService: LanguageService
  ) {
    this.form = fb.group({
      children: this.fb.array([
        new FormGroup({
          name: new FormControl(''),
          gradeLevelCD: new FormControl(''),
          createBy: new FormControl('PORTAL'),
          schoolName: new FormControl(''),
          classSubjectID: new FormArray([]), 
          // classSubjectsName: new FormArray([])
        })
      ])
    })

    this.form.valueChanges.subscribe({
      next: val => {

        let student = val.children
        
        this.dados.setDados('appointment', { student })

        console.log('➡️  student: ', student)
        this.createHtmlTable(student)
      }
    })
  }

  ngOnInit(): void {
    // this.dados.setDados('appointment', { student: [] })
  }

  children(): FormArray {
    return this.form.get('children') as FormArray
  }

  newChild(): FormGroup {
    return this.fb.group({
      name: new FormControl(''),
      gradeLevelCD: new FormControl(''),
      createBy: new FormControl('PORTAL'),
      schoolName: new FormControl(''),
      classSubjectID: new FormArray([]),
      // classSubjectsName: new FormArray([])
    })
  }

  addChild() {
    this.children().push(this.newChild())
  }

  removeChild(i: number) {
    this.children().removeAt(i)
  }

  onCheckChange(event: any, index: number) {
    const children = (this.form.get('children') as FormArray)
    const disciplinas = (children.controls[index].get('classSubjectID') as FormArray)   
    
    if (event.target.checked) {

      disciplinas.push(new FormControl(event.target.value))

    } else {
      const index = disciplinas.controls
        .findIndex(x => x.value === event.target.value)

        disciplinas.removeAt(index)
        
    }
    
  }

  createHtmlTable(form: any) {
    let tr = ''
    let table = ''
    let classSubjects

    for (let i = 0; i < form.length; i++) {

      classSubjects = form[i].classSubjectID.map((item: any) => {

        let name

        for (let key in this.disciplinas) {
          
          if(item === this.disciplinas[key].id) {
            name = this.disciplinas[key].value
          }
        }

        return ' ' + name

      })

      tr += `${form[i].name} - ${form[i].gradeLevelCD} - ${form[i].schoolName} - ${_.orderBy(classSubjects)}. | \r\n`

      table = `${this.language.labelChildData} \r\n${tr}`
     
    }

    console.log(table)

    this.dados.setDados('appointment', { serviceNotes: table })

  }

}
