<header>
  <div class="upper-header header-unidades d-flex justify-content-between">
    <div class="navbar-brand">
      <a [href]="siteUrl">
        <img
          class="kumon-logo"
          src="https://sitecontent.kumon.com.br/sitestg/kumon-website-pt/img/logo-black-kumon.png" />
      </a>
    </div>
    <h1 class="text-md-center navbar-text">
      <span class="title-header-unidade"> {{ language.welcome }} </span>
      <span class="name-header-unidade">{{ centerName }}</span>
    </h1>
    <div class="text-end navbar-text sm-hide">
      <span class="title-header-unidade" *ngIf="phone">{{ language.contact }}</span>
      <span class="phone-header-unidade" *ngIf="phone">{{ phone }}</span>
    </div>
  </div>
  <hr class="sm-show line-header" />

  <nav class="navbar navbar-expand-lg nav-unidades sm-hide">
    <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
      <a
        class="btn btn-transparent btn-primary btn-conheca-header"
        [href]="siteUrl + conhecaUnidade">{{ language.labelMeetCenter }}</a>

      <a
        class="btn btn-transparent btn-primary btn-met-header"
        [href]="siteUrl + metodo"
        onclick="gaTrigger('MONITOR_MENU_FRANQUIA', 'click', 'Menu-Metodo-Kumon')">{{ language.labelMethod }}</a>

      <a class="btn btn-transparent btn-primary btn-dep-header" [href]="siteUrl + depoimentos">{{ language.labelDepositions }}</a>
    </div>
  </nav>
</header>