import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZÀ-ž' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  numbersOnly(e: any) {
    let regex = new RegExp("^[0-9]*$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  removeSpaces(text: any) {

    if (!text) return

    return text.trim().replace(/\s+/g, " ")
  }

}
