<div class="component">
  <form [formGroup]="formCalendar">
    <p-calendar
      formControlName="date"
      class="calendar"
      (onSelect)="selectDate()"
      [inline]="true"
      [showWeek]="false"
      [style]="{ width: '100%' }"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [disabledDates]="invalidDates"></p-calendar>
  </form>
</div>