<form [formGroup]="form">
  <div formArrayName="children">
    <div
      class="container-form"
      *ngFor="let quantity of children().controls; let i = index"
      [formGroupName]="i">
      <div class="nome">
        <span class="p-float-label">
          <input type="text" pInputText class="p-inputtext-sm" formControlName="name" maxlength="25" />
          <label>{{language.labelName}}</label>
        </span>
      </div>


      <div class="serie">
        <span class="p-float-label">

          <p-dropdown
            formControlName="gradeLevelCD"
            [options]="schoolGrade"
            optionLabel="gradeLevelDescription"
            optionValue="gradeLevelName"
            [emptyMessage]="language.emptyMessage"
            class="p-inputtext-sm"
            [placeholder]="language.labelSchoolGrade"
            scrollHeight="300px"></p-dropdown>
          <label>{{language.labelSchoolGrade}}</label>
        </span>
      </div>

      <div class="escola">
        <span class="p-float-label">
          <input type="text" pInputText class="p-inputtext-sm" formControlName="schoolName" />
          <label>{{language.labelSchoolName}}</label>
        </span>
      </div>

      <div class="disciplinas">
        <div class="form-check" *ngFor="let disciplina of disciplinas">
          <div>
            <input
              class="form-check-input"
              type="checkbox"
              [value]="disciplina.id"
              [attr.data-name]="disciplina.value"
              [id]="disciplina.id + i"
              formArrayName="classSubjectID"
              (change)="onCheckChange($event, i)" />
            <label class="form-check-label" [for]="disciplina.id + i">
              {{ disciplina.value }}
            </label>
          </div>
        </div>
      </div>
      <div class="remover">
        <button type="button" class="btnRemoveChild" (click)="removeChild(i)" *ngIf="i > 0">
          <i class="fas fa-minus"></i> <span>{{language.buttonRemoveChild}}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="btnAddChild">
    <button type="button" (click)="addChild()" *ngIf="children().length < 5">
      <i class="fas fa-plus icon"></i>
      <span>{{language.buttonAddChild}}</span>
    </button>
  </div>
</form>