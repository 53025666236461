<form [formGroup]="form">
  <div class="box-modalidade">
    <ng-container class="wrapper-label" *ngFor="let service of services; let i = index">
      <label class="full-label label-modalidade" *ngIf="!service.isHiddenFromCustomers">
        <input
          type="radio"
          [value]="service.id"
          formControlName="modalidades"
          (change)="getValueFromCheckedService()"
          name="modalidades" />
        <div>
          <ng-container [ngSwitch]="service.isLocationOnline">
            <i *ngSwitchCase="false" class="fas fa-user-friends icon"></i>
            <i *ngSwitchCase="true" class="fas fa-laptop icon"></i>
          </ng-container>
          <span class="type">
            {{
            service.isLocationOnline
            ? language.serviceOnline
            : language.serviceoffline
            }}
          </span>
          <span class="check checked"><i class="fas fa-check"></i></span>
        </div>
      </label>
    </ng-container>
  </div>
</form>