import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

const BOOKING_SERVICE = new HttpContextToken(() => false)
const ENROLLMENT_SERVICE  = new HttpContextToken(() => false)

export function bookingService() {
  return new HttpContext().set(BOOKING_SERVICE, true)
}

export function enrollment() {
  return new HttpContext().set(ENROLLMENT_SERVICE, true)
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.context.get(BOOKING_SERVICE)) {

      const headers = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${sessionStorage.getItem('token-AgendamentoOnline')}`)
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Credentials', 'true')
      })

      return next.handle(headers)

    } else if (request.context.get(ENROLLMENT_SERVICE)) {

      const headers = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${sessionStorage.getItem('token-Enrollment')}`)
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Credentials', 'true')
      })

      // console.log(sessionStorage.getItem('token-Enrollment'))

      return next.handle(headers)

    } else {

      const headers = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${sessionStorage.getItem('token-appointment')}`)
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Credentials', 'true')
      })

      return next.handle(headers)
    }


  }
}
