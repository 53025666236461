import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ApiService } from 'src/app/services/api.service'

import * as _ from 'lodash'
import { LanguageService } from 'src/app/services/language.service'
import { Service } from 'src/app/models/language.model'
@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnChanges {

  language: Service = this.languageService.service

  @Input() services: any[] = []
  @Output() formStatus = new EventEmitter<any>()

  form!: FormGroup

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private languageService: LanguageService
  ) {
    this.form = this.fb.group({
      modalidades: new FormControl('', [Validators.required])
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.services.length > 0) this.setServiceCheckedOnInit()
  }

  setServiceCheckedOnInit() {

    let arr = _.find(this.services, item => !item.isHiddenFromCustomers)

    this.form.setValue({
      modalidades: arr.id
    })

    this.getValueFromCheckedService()
  }


  getValueFromCheckedService() {

    let arr = _.find(this.services, item => {

      return item.id === this.form.getRawValue().modalidades
    })

    this.formStatus.emit(this.form.valid)

    this.apiService.selectedService(arr)

  }

}
