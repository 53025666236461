import { Component, input } from '@angular/core';
import { Parent } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-info',
  standalone: true,
  template: `
    <div>
      <p class=label>{{language.labelFirstName}}</p>
      <p class=nome>{{ nome() }}</p>
    </div>
  `,
  styles: `
    .label {
      margin: 0;
      font-weight: 500;
      font-size: .875rem;
      margin-bottom: .3rem
    }

    .nome {
      font-size: 1.1rem;
      margin: 0;
      font-weight: 500;
      // color: #009ada;
      // color: #f0729a;
      color: #999;
      height: 3.125rem;
      border-radius: .4rem;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border: solid 1px #ececec;
      user-select: none;
      cursor: default;
    }
  `
})
export class InfoComponent {

  language: Parent = this.languageService.parent

  constructor(private languageService: LanguageService){}

  nome = input('')

}
