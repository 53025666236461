import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import { FormControl, FormGroup } from '@angular/forms'
import { SelectedDate } from 'src/app/models/selectedDate.model'

import * as _ from 'lodash'

import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration'
import * as ObjectSupport from 'dayjs/plugin/objectSupport'
import { LanguageService } from 'src/app/services/language.service'
import { Calendar } from 'src/app/models/language.model'

dayjs.extend(duration)
dayjs.extend(ObjectSupport)

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
})
export class CalendarioComponent implements OnInit, OnChanges {

  language: Calendar = this.languageService.calendar

  invalidDates: Date[] = []
  minDate = dayjs().add(1, 'day').toDate()
  maxDate!: Date

  formCalendar = new FormGroup({
    date: new FormControl()
  })

  @Input() availableDays: any[] = []
  @Input() defaultDuration: string = ''
  @Input() calendarMaxRange!: number

  @Output() selectedDate = new EventEmitter<SelectedDate>()
  @Output() formStatus = new EventEmitter()

  constructor(
    private config: PrimeNGConfig,
    private languageService: LanguageService
  ) { }


  ngOnChanges(): void {

    if (this.availableDays.length > 0) {

      this.maxDate = dayjs(_.last(this.validDays())).toDate()
      this.createCalendar(this.validDays())
      this.selectDate()
    } else {
      this.maxDate = dayjs().toDate()
    }
  }

  ngOnInit() {
    this.configCalendar()
  }

  validDays() {

    let validSlotSize = _.filter(this.availableDays, item => {
      const start = dayjs.duration(
        {
          hours: dayjs(item.startDateTime.dateTime).hour(),
          minutes: dayjs(item.startDateTime.dateTime).minute(),
        }
      ).asMinutes()

      const end = dayjs.duration(
        {
          hours: dayjs(item.endDateTime.dateTime).hour(),
          minutes: dayjs(item.endDateTime.dateTime).minute(),
        }
      ).asMinutes()

      const duration = dayjs.duration(this.defaultDuration).asMinutes()

      return (end - start) >= duration

    })

    return _.map(validSlotSize, (item) => {
      return dayjs(item.startDateTime.dateTime).format('YYYY-MM-DD')
    })
  }

  numberDaysInMonth(obj: any) {

    let number = dayjs(
      dayjs(obj).format('YYYY-MM-DD'),
    ).daysInMonth()

    return {
      year: obj.year,
      month: obj.month,
      numberDays: number
    }
  }

  createCalendar(validDays: any[]) {

    if (!validDays) return

    let startCalendar = {
      year: dayjs().year(),
      month: dayjs().month()
    }
    let endCalendar = []
    let numberMonths = 0

    do {
      numberMonths += 1
      endCalendar.push(dayjs().add(numberMonths, 'month').format('YYYY-MM-DD'))
    } while (
      numberMonths < this.calendarMaxRange
    )

    const endCalendarAsObj = _.map(endCalendar, item => {

      const [year, month] = _.split(item, '-')

      const result = {
        year: Number(year),
        month: Number(_.trimStart(month, '0')) - 1
      }

      return this.numberDaysInMonth(result)
    })

    const createCalendarRange = _.concat(this.numberDaysInMonth(startCalendar), endCalendarAsObj)

    const allDaysInRange = _.flatMap(_.orderBy(createCalendarRange, ['month'], ['asc']), (item: any) => {

      let days = []

      for (let i = 0; i < item.numberDays; i++) {
        days.push(
          dayjs({
            year: item.year,
            month: item.month,
            day: i + 1,
          }).format('YYYY-MM-DD')
        )
      }

      return days
    })

    const getInvalidDays = _.difference(allDaysInRange, validDays)

    const invalidDaysToDate = _.map(getInvalidDays, item => {
      return dayjs(item).toDate()
    })

    this.invalidDates = invalidDaysToDate

  }


  selectDate() {

    if (!this.formCalendar.get('date')?.value) {
      this.formCalendar.setValue({
        date: dayjs(this.validDays()[0]).toDate()
      })
    }

    let arrSelectedDay = _.filter(this.availableDays, item => {
      let selected = item.startDateTime.dateTime

      return (
        dayjs(selected).format('YYYY-MM-DD') ===
        dayjs(this.formCalendar.value.date).format('YYYY-MM-DD')
      )
    })

    let arrSlotInterval = _.map(arrSelectedDay, (item) => {
      const start = dayjs.duration(
        {
          hours: dayjs(item.startDateTime.dateTime).hour(),
          minutes: dayjs(item.startDateTime.dateTime).minute(),
        }
      ).asMinutes()

      const end = dayjs.duration(
        {
          hours: dayjs(item.endDateTime.dateTime).hour(),
          minutes: dayjs(item.endDateTime.dateTime).minute(),
        }
      ).asMinutes()

      return {
        startDateTime: start,
        endDateTime: end,
      }
    })

    this.selectedDate.emit({
      date: dayjs(arrSelectedDay[0].startDateTime.dateTime).format('YYYY-MM-DD'),
      slotInterval: arrSlotInterval
    })

    this.formStatus.emit(!!this.formCalendar.value.date)

  }

  configCalendar() {
    this.config.setTranslation({
      firstDayOfWeek: 0,
      dayNames: this.language.dayNames,
      dayNamesShort: this.language.dayNamesShort,
      dayNamesMin: this.language.dayNamesMin,
      monthNames: this.language.monthNames,
      monthNamesShort: this.language.monthNamesShort,
      today: this.language.today,
      clear: this.language.clear,
    })
  }


}
