<div class="box-duracao">
  <label *ngFor="let time of timeList; let i = index" class="full-label label-duracao">
    <input
      type="radio"
      name="duracaoAtendimento"
      [value]="time"
      required
      (change)="getTime($event)"
      [(ngModel)]="setTime" />
    <span>{{ time }}</span>
  </label>
</div>