import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'

import * as _ from 'lodash'

import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration'

dayjs.extend(duration)
@Component({
  selector: 'app-hora-atendimento',
  templateUrl: './hora-atendimento.component.html',
  styleUrls: ['./hora-atendimento.component.scss']
})
export class HoraAtendimentoComponent implements OnChanges {

  @Input() slots: any[] = []
  @Input() defaultDuration: string = ''

  @Output() selectedTime = new EventEmitter<string>()
  @Output() formStatus = new EventEmitter<boolean>()

  timeList: string[] = []
  setTime: string = ''

  constructor() { }

  ngOnChanges(): void {
    if (this.slots.length > 0) {
      this.setTime = ''

      this.createTimeButtons(this.slots)
    }
  }

  createTimeButtons(arr: any[]) {

    const list = _.flatMap(arr, value => {

      let start = value.startDateTime
      let end = value.endDateTime
      let interval = dayjs.duration(this.defaultDuration).asMinutes()
      let slots = this.generateInterval(start, end, interval)

      return slots
    })

    this.timeList = _.uniq(list)

  }

  generateInterval(start: number, end: number, interval: number) {

    let listTime = []

    for (let i = 0; start < 24 * 60; i++) {
      if (start > end) break

      let hh = Math.floor(start / 60) // getting hours of day in 0-24 format

      let mm = start % 60 // getting minutes of the hour in 0-55 format

      listTime[i] = `${('0' + (hh % 24)).slice(-2)}:${('0' + mm).slice(-2)}`

      start = start + interval
    }

    return _.dropRight(listTime)
  }


  getTime(e: any) {

    let timeCheck = e.target as HTMLInputElement

    this.selectedTime.emit(this.setTime)
    this.formStatus.emit(timeCheck.checked)
  }
}
