import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './shared/header/header.component'
import { HomeComponent } from './pages/home/home.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ButtonComponent } from './shared/button/button.component'
import { LabelComponent } from './shared/label/label.component'
import { LoadingComponent } from './shared/loading/loading.component'
import { InputErrorComponent } from './shared/input-error/input-error.component'
import { HoraAtendimentoComponent } from './components/hora-atendimento/hora-atendimento.component'
import { ServicosComponent } from './components/servicos/servicos.component'
import { MessageComponent } from './shared/message/message.component'
import { CalendarioComponent } from './components/calendario/calendario.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ContatoResponsavelComponent } from './components/contato-responsavel/contato-responsavel.component'
import { DialogModule } from 'primeng/dialog'
import { InputTextModule } from 'primeng/inputtext'
import { CalendarModule } from 'primeng/calendar'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { ConcluidoComponent } from './pages/concluido/concluido.component'
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe'
import { DadosAlunoComponent } from './components/dados-aluno/dados-aluno.component'
import { ContatoEmailComponent } from './components/contato-email/contato-email.component'
import { SpyDirective } from './directives/spy.directive'
import { AuthenticationInterceptor } from './interceptors/authenticate.service'
import { VersionComponent } from './shared/version/version.component'
import { CheckComponent } from './components/check/check.component'
import { InfoComponent } from "./shared/info/info.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LabelComponent,
    ButtonComponent,
    LoadingComponent,
    InputErrorComponent,
    HoraAtendimentoComponent,
    ServicosComponent,
    MessageComponent,
    CalendarioComponent,
    ContatoResponsavelComponent,
    ConcluidoComponent,
    SanitizeHtmlPipe,
    DadosAlunoComponent,
    ContatoEmailComponent,
    SpyDirective,
    VersionComponent,
    CheckComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaskDirective, NgxMaskPipe,
    CalendarModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    InfoComponent
],
  providers: [
    provideNgxMask(),
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-53N3BJS'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
