
import { ConcluidoComponent } from './pages/concluido/concluido.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './pages/home/home.component'
import { CheckComponent } from './components/check/check.component'

const routes: Routes = [
  { path: ':state/:local', component: HomeComponent },
  { path: 'concluido/:state/:local', component: ConcluidoComponent, pathMatch: 'full' },
  { path: ':state/:local/:hash', component: HomeComponent, pathMatch: 'full' },
  { path: 'check', component: CheckComponent },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
