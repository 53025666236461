import { DadosService } from 'src/app/services/dados.service'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { AppService } from 'src/app/services/app.service'
import { LanguageService } from 'src/app/services/language.service'
import { InputError, Parent } from 'src/app/models/language.model'

@Component({
  selector: 'app-contato-responsavel',
  templateUrl: './contato-responsavel.component.html',
  styleUrls: ['./contato-responsavel.component.scss']
})
export class ContatoResponsavelComponent implements OnInit {

  language: Parent = this.languageService.parent
  languageError: InputError = this.languageService.inputError
  country: string = this.language.country

  // Telefone
  format = ''
  placeholder = ''
  prefix: string = '+55'

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž']+\s+[a-zA-ZÀ-ž']+/)]),
    emailAddress: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\.\-\+_]*@[a-zA-Z0-9]{1,}[a-zA-Z0-9\.\-_]*\.[a-z]{2,}$/)]),
    phone: new FormControl()
  })

  @Output() formStatus = new EventEmitter<any>()

  constructor(
    private dados: DadosService,
    private appService: AppService,
    private languageService: LanguageService
  ) {

    this.form.valueChanges.subscribe({
      next: val => {

        let customers = this.dados.getDados('appointment').customers.map((item: any) => {
          return {
            "@odata.type": "#microsoft.graph.bookingCustomerInformation",
            customerId: "",
            name: this.appService.removeSpaces(val.name),
            emailAddress: val.emailAddress,
            phone: val.phone
          }
        })

        this.dados.setDados('appointment', { customers })

        this.formStatus.emit(this.form.valid)

      }
    })
  }

  ngOnInit(): void {
    this.changeFormByCountry()
  }

  changeFormByCountry() {
    switch (this.country) {

      case 'BR':
        this.format = '(00) 00000-0000'
        this.placeholder = '(00) 00000-0000'
        this.form.get('phone')?.setValidators([Validators.required, Validators.minLength(11)])
        this.form.get('phone')?.updateValueAndValidity()
        break

      default:
        this.format = '99999999999'
        this.form.get('phone')?.setValidators([Validators.required, Validators.minLength(5)])
        this.form.get('phone')?.updateValueAndValidity()
    }
  }

  onInputName(e: any) {
    return this.appService.alphaOnly(e)
  }

  onInputPhone(e: any) {
    return this.appService.numbersOnly(e)
  }
}
