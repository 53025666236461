import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  @Input() label: string = ''
  @Input() icon: string = ''
  @Input() weight: string = 'regular'
  @Input() description: string = ''

  constructor() { }

  ngOnInit(): void {

    switch (this.weight) {
      case 'regular':
        this.weight = 'r'
        break

      case 'solid':
        this.weight = 's'
        break
    }

  }

}
